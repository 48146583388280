<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 py-4 px-8">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
        <div
            v-if="hasCredentials"
            id="bluemoon-application-container"
        ></div>
        <div
            v-else
            class="w-full text-center align-center mt-4"
        >
            <p>please set up the blue moon property to continue</p>
        </div>
    </div>
</template>

<script type="text/javascript">
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { BM_CREDENTIALS_ERROR } from '@/utils/constants/leasing.js';

export default {
    components: { Loader },
    mixins: [NotifyMixin],
    data() {
        return {
            isCompleted: false,
            applicationCompleted: false,
            loading: false,
            token: '',
            id: '',
            hasCredentials: false,
            application_status: 'Application not Submitted',
            property: {
                id: '15430', // Fake property id
                name: '',
            },

            params: {
                view: 'review',
                skipStartPage: true,
                applicationId: '',
                applicationData: {},
            },

            document_instance_created: false,
        };
    },

    computed: {
        ...mapGetters({
            getDocumentInstance: 'application/getDocumentInstance',
            getApplication: 'application/getApplication',
            currentProfile: 'auth/currentProfile',
            getApplicationId: 'application/getApplicationId',
            getbluemoon_credentials: 'application/getbluemoon_credentials',
            getOccupancyData: 'leasing/getOccupancyData',
        }),

        getLeaseTerms() {
            const diff = moment(this.getOccupancyData.phaseEndDate).diff(moment(this.getOccupancyData.phaseStartDate), 'months', true);
            return `${Math.floor(diff)} months`;
        },
    },

    mounted() {
        this.fetchbluemoon_credentials();
    },

    methods: {
        ...mapActions({
            setDocumentInstance: 'application/setDocumentInstance',
            clearState: 'application/clearState',
            setbluemoon_credentials: 'application/setbluemoon_credentials',
        }),

        fetchbluemoon_credentials() {
            this.$partnerDataProvider
                .getOne('community_blue_moon', {
                    customerUUID: this.currentProfile.customerId,
                    communityUUID: this.currentProfile.communityId,
                    purpose: 'AplicationLeasingGeneration',
                })
                .then(res => {
                    if (res.platformData) {
                        this.hasCredentials = true;
                        this.setbluemoon_credentials(res.platformData);
                        this.getAuthBlueMoonToken();
                    }
                })
                .catch(() => {
                    this.hasCredentials = false;
                    this.notifyError(BM_CREDENTIALS_ERROR);
                })
                .finally((this.loading = false));
        },

        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials?.username,
                        password: this.getbluemoon_credentials?.password,
                        scope: 'full',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials?.client_id,
                        client_secret: this.getbluemoon_credentials?.client_secret,
                        serial_number: this.getbluemoon_credentials?.serial_number,
                    },
                })
                .then(async res => {
                    if (this.$route.params.id) this.id = this.$route.params.id;
                    this.token = res.access_token;
                    await this.getProperty(this.getbluemoon_credentials?.serial_number, res.access_token);
                })
                .catch(() => {
                    this.notifyError('An authentication error occurred with Blue Moon');
                    this.loading = false;
                });
        },

        getProperty(account_id, token) {
            this.$bmServiceDataProvider
                .getList('property', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.property = res.data.find(property => property.account_id === account_id);
                    await this.leaseApplication();
                })
                .catch(() => {
                    this.notifyError('An error occurred getting the property from Blue Moon');
                    this.loading = false;
                });
        },

        leaseApplication() {
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(this.token, this.property.id);
            const applicationElement = bluemoonElements.load('bluemoon-application-container', 'bm-rental-application', {
                ...this.params,
                applicationId: this.getDocumentInstance.id,
                applicationData: this.getDocumentInstance ? { ...this.getDocumentInstance } : {},
            });

            applicationElement.addEventListener('onElementLoaded', e => {
                if (e.detail.elementId) this.loading = false;
            });

            applicationElement.addEventListener('onReviewBack', () => {
                this.$router.push({ name: 'application_service.index' });
            });
            applicationElement.addEventListener('onReviewComplete', e => {
                const application = {
                    ...this.getDocumentInstance,
                    completed: e.detail.completed,
                    transactionId: e.detail.transactionId,
                };
                this.setDocumentInstance(application);
                this.$router.push({ name: 'application_service.esign-application' });
            });
        },
    },
};
</script>
